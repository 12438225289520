<zeva-card class="zebot-container">
  <zeva-card-header class="action-header">
    <div class="profile">
      <img class="bot-icon" src="/assets/icons/social-media/zeva-icon.png" />
      <span class="mat-headline-5">Alan</span>
      <span class="beta mat-small">beta</span>
    </div>
    <div>
      <button mat-icon-button (click)="minimize.emit()">
        <mat-icon>horizontal_rule</mat-icon>
      </button>
      <button mat-icon-button (click)="endChatFlag.set(true)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </zeva-card-header>

  <div class="description">
    <mat-icon svgIcon="zebot" color="primary"></mat-icon>
    <span>Alan, our AI vehicle analytics assistant, knows your car better than you do — and he's here to help!</span>
  </div>

  <div>
    <div class="messages" #chatContainer>
      <p class="date">Today</p>
      <div
        *ngFor="let message of messages; let i = index"
        class="line"
        [ngClass]="{ bot: message.sender === 'bot', user: message.sender === 'user' }"
      >
        <img
          *ngIf="message.sender === 'bot'"
          class="bot-icon"
          [class.invisible]="i < messages.length - 1 && messages[i + 1].sender === 'bot'"
          src="/assets/icons/social-media/zeva-icon.png"
        />
        <div class="message" [class.dark]="(themeService.theme$ | async) === Theme.DARK">
          <span *ngIf="message.thinking" class="thinking">
            <span><mat-icon>fiber_manual_record</mat-icon></span>
            <span><mat-icon>fiber_manual_record</mat-icon></span>
            <span><mat-icon>fiber_manual_record</mat-icon></span>
          </span>
          <span *ngIf="!message.thinking">{{ message.text }}</span>
          <div *ngIf="message.feedback" class="feedback">
            <button mat-icon-button color="accent" (click)="provideFeedback(true)">
              <mat-icon>thumb_up</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="provideFeedback(false)">
              <mat-icon>thumb_down</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="user-input">
      <mat-form-field class="form-field">
        <input
          matInput
          placeholder="Message..."
          [disabled]="awaitingFeedback()"
          #userInput
          (keydown.enter)="sendMessage(userInput.value); userInput.value = ''"
        />
        <button
          matSuffix
          mat-icon-button
          [disabled]="awaitingFeedback() || !userInput.value"
          (click)="sendMessage(userInput.value); userInput.value = ''"
        >
          <mat-icon svgIcon="send"></mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div class="layover" *ngIf="endChatFlag()"></div>
  <div class="end-chat" *ngIf="endChatFlag()">
    <p>End Chat</p>
    <mat-divider></mat-divider>
    <span>Are you sure you want to end chat?</span>
    <button mat-flat-button color="primary" (click)="endChat()">End Chat</button>
    <span (click)="endChatFlag.set(false)" class="cancel">Cancel</span>
  </div>
</zeva-card>
