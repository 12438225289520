import { HttpClient, HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, firstValueFrom, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClientWrapper } from '../../http-client-wrapper';

import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ZEVA_DATE_DISPLAY } from '../../core/core.static';
import { TrackerService } from '../../core/services/tracker.service';
import { resetState } from '../../store/app.actions';
import { SSOProvider } from '../pages/sso/sso.models';
import { getCompanies } from '../store/auth.actions';
import { selectUserProfile } from '../store/auth.selectors';
import {
  Company,
  CompanyDataType,
  ForgotPasswordResponse,
  IpData,
  ResetPasswordResponse,
  SessionResponse,
  SignInResponse,
  UserCanDeleteResponse,
  UserPermissions,
  UserPreferencesDTO,
} from './auth.models';
import {
  changePasswordUrl,
  csrfUrl,
  emailVerificationUrl,
  forgotPasswordUrl,
  namedPermissionsUserUrl,
  permissionsUserUrl,
  refreshSessionUrl,
  resetPasswordUrl,
  sessionUrl,
  signInUrl,
  signOutUrl,
  ssoSignInUrl,
  ssoSignUpUrl,
  updateUserPreferencesUrl,
  updateUserUrl,
  userCompaniesUrl,
  userDeleteUrl,
  userPreferencesUrl,
  verifyResetTokenUrl,
} from './auth.urls';

@Injectable()
export class AuthService {
  // --------------- "Keep me signed in" local storage logic

  public static readonly KEEP_ALIVE_TOKEN = 'zeva_keep_signed_in';

  set keepSignedIn(toggle: boolean) {
    window.localStorage.setItem(AuthService.KEEP_ALIVE_TOKEN, toggle ? 'true' : 'false');
    this._keepSignedIn$.next(toggle);
  }
  get keepSignedIn(): boolean {
    return window.localStorage.getItem(AuthService.KEEP_ALIVE_TOKEN) === 'true';
  }
  private _keepSignedIn$ = new Subject<boolean>();
  get keepSignedIn$() {
    return this._keepSignedIn$.asObservable();
  }

  // --------------- End of "Keep me signed in"

  // --------------- "Account(Company) Selection" local storage logic

  public static readonly ACCOUNT_SELECTION_TOKEN = 'zeva_selected_account';
  set selectedAccount(accountName: string | null) {
    if (accountName !== null)
      window.localStorage.setItem(AuthService.ACCOUNT_SELECTION_TOKEN, accountName);
    else window.localStorage.removeItem(AuthService.ACCOUNT_SELECTION_TOKEN);

    this._selectedAccount$.next(accountName);
  }
  get selectedAccount() {
    return window.localStorage.getItem(AuthService.ACCOUNT_SELECTION_TOKEN);
  }
  private _selectedAccount$ = new Subject<string | null>();
  get selectedAccount$() {
    return this._selectedAccount$.asObservable();
  }

  // --------------- End of "Account(Company) Selection"

  // --------------- IP Data API

  private readonly GEOLITE_ACCOUNT_ID = 1062099;
  private readonly GEOLITE_LICENSE_KEY = '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';
  private readonly IPGEOLOCATION_API_KEY = 'b15e782668a44c42944cd81a87fe76ce';

  // --------------- End of IP Data API

  expiryDate?: moment.Moment;
  public readonly DEFAULT_TERMINATION_PERIOD_DAYS = 7;
  public readonly DEFAULT_TERMINATION_DATE = moment().add(
    this.DEFAULT_TERMINATION_PERIOD_DAYS,
    'days'
  );
  public readonly ZEVA_DATE_FORMAT = ZEVA_DATE_DISPLAY;
  public readonly AccountType = CompanyDataType;

  constructor(
    private httpClient: HttpClient,
    private wrapper: HttpClientWrapper,
    private readonly router: Router,
    private readonly store: Store,
    private trackerService: TrackerService,
    private readonly snackBar: MatSnackBar
  ) {}

  public getCSRFToken(): Observable<HttpResponseBase> {
    return this.httpClient.get(environment.apiUrl + csrfUrl, {
      observe: 'response',
      withCredentials: true,
    });
  }

  public getSession(): Observable<SessionResponse> {
    return this.httpClient.get<SessionResponse>(environment.apiUrl + sessionUrl, {
      withCredentials: true,
    });
  }

  public signIn(username: string, password: string): Observable<SignInResponse> {
    return this.wrapper.post(signInUrl, {
      username,
      password,
    });
  }

  public ssoSignIn(
    provider: SSOProvider,
    code: string,
    redirect_uri?: string
  ): Observable<SignInResponse> {
    return this.wrapper.post(ssoSignInUrl, {
      provider,
      code,
      redirect_uri,
    });
  }

  public ssoSignUp(
    provider: SSOProvider,
    code: string,
    redirect_uri?: string
  ): Observable<SignInResponse> {
    return this.wrapper.post(ssoSignUpUrl, {
      provider,
      code,
      redirect_uri,
    });
  }

  public signOut(): Observable<unknown> {
    return this.httpClient.get(environment.apiUrl + signOutUrl, { withCredentials: true });
  }

  public refreshSession(): Observable<unknown> {
    return this.httpClient
      .get(environment.apiUrl + refreshSessionUrl, { withCredentials: true })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403)
            console.error('Session refresh failed: Access is forbidden (403).');
          else console.error('An error occurred:', error.message);

          this.signOut();

          return throwError(() => new Error('Failed to refresh session. Please try again.'));
        })
      );
  }
  public forgotPassword(email: string): Observable<ForgotPasswordResponse> {
    return this.wrapper.post<ForgotPasswordResponse>(forgotPasswordUrl, {
      email: email,
    });
  }

  public resetPassword(
    email: string,
    code: string,
    password: string
  ): Observable<ResetPasswordResponse> {
    return this.wrapper.post<ResetPasswordResponse>(resetPasswordUrl, {
      email: email,
      password: password,
      token: code,
    });
  }

  public changePassword(old_password: string, new_password: string) {
    return this.wrapper.post(changePasswordUrl, {
      old_password,
      new_password,
    });
  }

  public updateUserInfo(
    first_name?: string,
    email?: string,
    last_name?: string,
    phone_number?: string,
    postal_code?: string,
    birth_year?: number,
    gender?: string,
    country?: string
  ) {
    return this.wrapper.post(updateUserUrl, {
      first_name,
      email,
      last_name,
      phone_number,
      postal_code,
      birth_year,
      gender,
      country,
    });
  }

  public requestVerification(forEmail?: string): Observable<{ success: boolean }> {
    const params = forEmail ? { email: forEmail } : undefined;
    return this.wrapper.get<{ success: boolean }>(emailVerificationUrl, params);
  }

  public verifyEmail(code: string, forEmail?: string): Observable<{ success: boolean }> {
    const body: { code: string; email?: string } = { code };
    if (forEmail) body['email'] = forEmail;
    return this.wrapper.post<{ success: boolean }>(emailVerificationUrl, body);
  }

  public checkCanDelete(): Observable<UserCanDeleteResponse> {
    return this.wrapper.get(userDeleteUrl);
  }

  public deleteUserProfile(user_id: number): Observable<string> {
    return this.wrapper.delete(`${userDeleteUrl}${user_id}/`, {});
  }

  public getCompanies(): Observable<Company[]> {
    return this.wrapper.get(userCompaniesUrl);
  }

  public deleteCompany(
    company: string,
    delete_after: string,
    immediately = false
  ): Observable<unknown> {
    return this.wrapper.delete(`${userCompaniesUrl + company}/`, { delete_after, immediately });
  }

  public reinstateCompany(company: string): Observable<unknown> {
    return this.wrapper.put(userCompaniesUrl + 'reinstate/', { company });
  }

  public getUserPermissions(): Observable<UserPermissions> {
    return this.wrapper.get(permissionsUserUrl);
  }

  public getUserNamedPermissions(): Observable<{ name: string; codename: string }[]> {
    return this.wrapper.get(namedPermissionsUserUrl);
  }

  public getUserPreferences(): Observable<UserPreferencesDTO | null> {
    return this.wrapper.get(userPreferencesUrl);
  }

  public updateUserPreferences(preferences: UserPreferencesDTO): Observable<UserPreferencesDTO> {
    return this.wrapper.post(updateUserPreferencesUrl, preferences);
  }

  public verifyResetToken(token: string): Observable<ForgotPasswordResponse> {
    return this.wrapper.get<ForgotPasswordResponse>(verifyResetTokenUrl, {
      token: token,
    });
  }

  public getIpData(): Observable<IpData> {
    return this.httpClient.get<IpData>('https://api.country.is/').pipe(
      timeout(3000), // Sometimes the API takes too long to respond and we don't want to wait
      catchError(() => {
        return this.httpClient
          .get<{ ip: string; country_code2: string }>(
            `https://api.ipgeolocation.io/ipgeo?apiKey=${this.IPGEOLOCATION_API_KEY}&fields=country_code2`
          )
          .pipe(
            map((data: { ip: string; country_code2: string }) => ({
              ip: data.ip,
              country: data.country_code2,
            }))
          );
      })
    );
    // CORS issue
    // const headers = new HttpHeaders();
    // headers.append(
    //   'Authorization',
    //   'Basic ' + btoa(`${this.GEOLITE_ACCOUNT_ID}:${this.GEOLITE_LICENSE_KEY}`)
    // );
    // return this.httpClient.get<IpData>('https://geolite.info/geoip/v2.1/country/me', { headers });
  }

  public async completeSignOut(queryParams?: Params): Promise<void> {
    await firstValueFrom(this.signOut());
    if (queryParams) void this.router.navigate(['auth', 'login'], { queryParams });
    else void this.router.navigate(['auth', 'login']);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        this.store.dispatch(resetState({ resetAuth: true }));
      });
  }

  deleteAccount(company: Company): void {
    const terminationDate = (this.expiryDate ?? this.DEFAULT_TERMINATION_DATE).toISOString();

    void firstValueFrom(
      this.deleteCompany(company.company_name, terminationDate, true).pipe(
        tap(() => this.store.dispatch(resetState({}))),
        tap(() => this.store.dispatch(getCompanies())),
        switchMap(() => this.store.select(selectUserProfile).pipe(take(1))),
        switchMap((user) => {
          if (!user) return of(null);

          return this.deleteUserProfile(user.id).pipe(
            tap(() => {
              this.trackerService.trackAction('ACCOUNT_DELETION', 'Profile', {
                immediate: true,
              });
              this.snackBar.open(
                'Your account has been deleted, and your Tesla account is now disconnected.',
                undefined,
                {
                  duration: 3000,
                }
              );
            }),
            catchError((err) => {
              console.warn('Error during user profile deletion:', err);
              return of(null);
            })
          );
        }),
        tap(() => {
          void this.router.navigate(['auth', 'login']);
          void this.router.events
            .pipe(
              filter((event) => event instanceof NavigationEnd),
              take(1)
            )
            .subscribe(() => {
              this.store.dispatch(resetState({ resetAuth: true }));
            });
        }),

        catchError((error) => {
          console.warn('Error during account termination process:', error);
          return of();
        })
      )
    );
  }
}
