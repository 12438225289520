import { ApplicationRef, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, Observable, Subscription, tap } from 'rxjs';
import { getUserPreferences } from '../../auth/store/auth.actions';
import { selectUserPreferences } from '../../auth/store/auth.selectors';
import { UserPreferencesDTO } from '../../auth/utility/auth.models';
import { Loadable } from '../../shared/loading-state/loadable';
import { AppState } from '../../store/app.reducers';

// Language enum for different language codes
export enum Language {
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  CN = 'cn',
  NL = 'nl',
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  language$ = new BehaviorSubject<Language>(Language.EN);
  currentLanguage$ = this.language$.asObservable();
  private readonly subscription = new Subscription();

  constructor(
    private ref: ApplicationRef,
    private readonly store: Store<AppState>,
    private readonly translocoService: TranslocoService
  ) {
    let preferredLanguage = Language.EN; // Default language
    const firstUserPreferences$ = this.store.select(selectUserPreferences).pipe(
      tap((preferences: Loadable<UserPreferencesDTO | null>) => {
        if (preferences.value === undefined && !preferences.error && !preferences.isLoading)
          this.store.dispatch(getUserPreferences());
      }),
      filter((preferences: Loadable<UserPreferencesDTO | null>) => !!preferences.value),
      tap((preferences: Loadable<UserPreferencesDTO | null>) => {
        const value: UserPreferencesDTO = preferences.value as UserPreferencesDTO;
        if (value.language) {
          preferredLanguage = value.language as Language;
          localStorage.setItem('preferredLanguage', value.language);
          this.setLanguage(preferredLanguage);
        }
      })
    );
    this.subscription.add(firstUserPreferences$.subscribe());
    // Trigger refresh of UI
    this.ref.tick();
  }

  // Set the language and update TranslocoService
  setLanguage(language: Language): void {
    document.documentElement.lang = language; // Set the HTML lang attribute
    this.language$.next(language);
    this.translocoService.setActiveLang(language);
  }

  // Returns an observable for the user's language preferences
  getLanguage(): Observable<Loadable<UserPreferencesDTO | null>> {
    const listenUserPreferences$ = this.store.select(selectUserPreferences).pipe(
      tap((preferences: Loadable<UserPreferencesDTO | null>) => {
        if (preferences && preferences.value) {
          const value: UserPreferencesDTO = preferences.value;
          if (value.language) this.setLanguage(value.language as Language);
          else this.setLanguage(Language.EN); // Default to English if no preference is set
        } else {
          this.setLanguage(Language.EN); // Default if preferences are not available
        }
      })
    );
    return listenUserPreferences$;
  }

  get currentLanguage(): Language {
    return this.language$.value;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
