<div class="zeva-main-container">
  <mat-sidenav-container class="zeva-main-container">
    <mat-sidenav #sidenav mode="side" [opened]="sidenavService.opened" class="sidenav">
      <app-sidenav></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="drawer-content">
      <div class="drawer-boundary">
        <button mat-icon-button (click)="sidenavService.toggle()">
          <i class="material-icons">{{ sidenav.opened ? 'chevron_left' : 'menu' }}</i>
        </button>
      </div>

      <div class="content">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #content>
  <mat-sidenav-container class="zebot-main-container">
    <mat-sidenav-content class="drawer-content">
      <div class="content">
        <app-header></app-header>
        <router-outlet class="page-container"></router-outlet>
      </div>

      <div class="drawer-boundary" *ngIf="(user$ | async)?.is_superuser">
        <button
          mat-fab
          color="primary"
          (click)="zebotCont.toggle(); zebot.startChat()"
          class="zebot-toggle"
        >
          <mat-icon svgIcon="zebot"></mat-icon>
        </button>
      </div>
    </mat-sidenav-content>

    <mat-sidenav #zebotCont mode="side" position="end">
      <insights-zebot
        #zebot
        (minimize)="zebotCont.close()"
        (close)="zebotCont.close()"
      ></insights-zebot>
    </mat-sidenav>
  </mat-sidenav-container>
</ng-template>
