import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  PopoverComponent,
  PopoverContentDirective,
  PopoverTriggerDirective,
} from './popover.component';

@NgModule({
  declarations: [PopoverComponent, PopoverTriggerDirective, PopoverContentDirective],
  exports: [PopoverComponent, PopoverTriggerDirective, PopoverContentDirective, OverlayModule],
  imports: [CommonModule, OverlayModule],
})
export class PopoverModule {}
