import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProviderCredential } from 'src/app/admin/admin.models';
import { HttpClientWrapper } from 'src/app/http-client-wrapper';
import { Vehicle } from '../models/vehicle.class';
import { Fleet, FleetDTO, VehicleDTO } from '../models/vehicle.models';
import {
  providerCredentialsUrl,
  updateVehicleColorUrl,
  vehicleFleetsUrl,
  vehicleListUrl,
} from '../vehicle.urls';

@Injectable({ providedIn: 'root' })
export class VehicleDataService {
  constructor(private httpClientWrapper: HttpClientWrapper, private httpClient: HttpClient) {}

  /**
   * Returns a list of all vehicles
   * @returns list of all vehicles
   */
  getVehicleList(): Observable<Vehicle[]> {
    // return this.httpClientWrapper.get(`vehicle-list/`);
    return this.httpClientWrapper.get(vehicleListUrl);
  }
  /**
   * Creates new vehicle entries or modifies existing if passed VINs exist in the database.
   * @returns list of all vehicles: old ones + newly added
   */
  createVehicleList(vehicles: VehicleDTO[], tokenId?: number): Observable<Vehicle[]> {
    return this.httpClientWrapper.post(vehicleListUrl, { vehicles, token_id: tokenId });
  }
  /**
   * Permanently deletes vehicle entries with passed VINs
   * @returns list of all vehicles that remained in the database after deletion
   */
  deleteVehicleList(vehicleVINs: string[], permanent?: boolean): Observable<Vehicle[]> {
    return this.httpClientWrapper.delete(vehicleListUrl + 'delete/', {
      vehicles: vehicleVINs,
      permanent: permanent ?? false,
    });
  }

  unarchiveVehicleList(vehicleVINs: string[]): Observable<Vehicle[]> {
    return this.httpClientWrapper.put(vehicleListUrl + 'unarchive/', { vehicles: vehicleVINs });
  }

  /**
   * Updates vehicle details
   * @returns list of all vehicles: old ones + newly updated
   */
  updateVehicleDetails(vehicle: VehicleDTO): Observable<Vehicle[]> {
    return this.httpClientWrapper.post(vehicleListUrl + 'update_details/', { vehicle });
  }

  updateVehicleColor(
    vehicleId: string,
    newUserColor: string
  ): Observable<{ before: string; after: string }> {
    return this.httpClientWrapper.post(updateVehicleColorUrl, {
      vehicle: vehicleId,
      color: newUserColor,
    });
  }

  /**
   * Returns a list of all Fleets with their assigned vehicles
   * @returns list of all fleets
   */
  getFleetList(): Observable<Fleet[]> {
    return this.httpClientWrapper.get(vehicleFleetsUrl);
  }
  /**
   * Creates new fleet entries or modifies existing if passed fleet_name exist in the database
   * @returns list of all fleets: old ones + newly added
   */
  createFleetList(fleets: FleetDTO[]): Observable<Fleet[]> {
    return this.httpClientWrapper.post(vehicleFleetsUrl, { fleets });
  }
  /**
   * Permanently deletes fleet entries with passed fleet ids.
   * Unlinks all vehicles linked to those fleets
   * @returns list of all fleets that remained in the database after deletion
   */
  deleteFleetList(fleetIds: number[]): Observable<Fleet[]> {
    return this.httpClientWrapper.delete(vehicleFleetsUrl + 'delete/', { fleets: fleetIds });
  }

  /**
   * Assigns provided VINs to provided Fleets
   * @param fleetIds - ids of Fleets to assign vehicles to
   * @param vehicles - VINs of vehicles to be assigned to Fleets
   * @returns updated list of Fleet to Vehicle relations
   */
  addToFleets(fleetIds: number[], vehicles: string[]): Observable<Fleet[]> {
    return this.httpClientWrapper.put(vehicleFleetsUrl + 'assign/', {
      fleets: fleetIds,
      vehicles,
    });
  }

  /**
   * Removes assignment of provided VINs from provided Fleets
   * @param fleetIds - ids of Fleets to unassign vehicles from
   * @param vehicles - VINs of vehicles to be unassigned
   * @returns updated list of Fleet to Vehicle relations
   */
  removeFromFleets(fleetIds: number[], vehicles: string[]): Observable<Fleet[]> {
    return this.httpClientWrapper.delete(vehicleFleetsUrl + 'unassign/', {
      fleets: fleetIds,
      vehicles,
    });
  }

  /**
   * Returns a list of all provider credentials
   * @returns list of all provider credentials
   */
  getProviderCredentials(): Observable<ProviderCredential[]> {
    return this.httpClientWrapper.get(providerCredentialsUrl);
  }

  /**
   * Deletes a provider credential
   * @param credential - credential to delete
   * @returns list of all provider credentials that remained in the database after deletion
   */
  deleteProviderCredentials(credential: ProviderCredential): Observable<ProviderCredential[]> {
    return this.httpClientWrapper.delete(providerCredentialsUrl + String(credential.id), {});
  }
}
