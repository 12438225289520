import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { selectUserProfile } from 'src/app/auth/store/auth.selectors';
import { getRouterOutletState } from '../../shared/helpers/utility';
import { SidenavService } from '../sidenav/sidenav.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  // animations: [routerTransition],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {
  constructor(public readonly sidenavService: SidenavService, public store: Store) {}

  getState = getRouterOutletState;

  user$ = this.store.select(selectUserProfile).pipe(filter((u) => u !== undefined && u !== null));
}
