import * as moment from 'moment';
import { ProviderCredential } from '../../admin/admin.models';
import { VehicleDTO } from '../vehicle/models/vehicle.models';

/**
 * Plan types.
 */
export enum PlanType {
  CONSUMER = 'consumer',
  COMMERCIAL = 'commercial',
}

/**
 * Subscription cadence.
 */
export enum SubscriptionCadence {
  DAILY = 'daily',
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
  LIFETIME = 'lifetime',
}

/**
 * Subscription Plan as returned by the API.
 */
export type SubscriptionPlanDTO = {
  id: number;
  name: string;
  cadence: SubscriptionCadence;
  type: PlanType;
  price_per_spot: number;
  max_spots?: number;
  display_ads: boolean;
  invoice_only: boolean;
  free: boolean;
  mobile: boolean;
};

/**
 * Subscription states.
 */
export enum SubscriptionState {
  PENDING = 'pending', // Waiting for user to enter payment details
  TRIALING = 'trialing', // Trial period (for paid subscriptions)
  ACTIVE = 'active', // Active
  CANCELING = 'canceling', // Canceled but still active (for paid subscriptions)
  PAST_DUE = 'past_due', // Active but payment failed (for paid subscriptions)
  EXPIRED = 'expired', // Subscription ended (for paid subscriptions)
  TRIAL_END = 'trial_end', // Trial ended (For paid subscriptions)
}

/**
 * States with access to the platform
 */
export const ACTIVE_SUBSCRIPTION_STATES: SubscriptionState[] = [
  SubscriptionState.TRIALING,
  SubscriptionState.ACTIVE,
  SubscriptionState.CANCELING,
  SubscriptionState.PAST_DUE,
];

/**
 * States without access to the platform
 */
export const PENDING_SUBSCRIPTION_STATES = [
  SubscriptionState.PENDING,
  SubscriptionState.EXPIRED,
  SubscriptionState.TRIAL_END,
];

/**
 * Subscription Plan Instance as returned by the API.
 */
export type SubscriptionPlanInstanceDTO = {
  id: number;
  plan: SubscriptionPlanDTO;
};

/**
 * Subscription as returned by the API.
 */
export type SubscriptionDTO = {
  id: number;
  company: number;
  state: SubscriptionState;
  start_date: string;
  plan_instance: SubscriptionPlanInstanceDTO;
  is_active: boolean;
  display_ads: boolean;
  purchased_spots: number;
  max_spots?: number;
  price_per_spot: number;
  next_billing_date: string | null;
  days_until_next_bill: number | null;
  grace_period_days: number;
  payment_gateway: string;
};

/**
 * Display names for the subscription states.
 */
export const SUBSCRIPTION_STATE_DISPLAY: { [key in SubscriptionState]: string } = {
  [SubscriptionState.PENDING]: 'subscription_state_pending',
  [SubscriptionState.TRIALING]: 'subscription_state_trialing',
  [SubscriptionState.ACTIVE]: 'subscription_state_active',
  [SubscriptionState.CANCELING]: 'subscription_state_canceling',
  [SubscriptionState.PAST_DUE]: 'subscription_state_past_due',
  [SubscriptionState.EXPIRED]: 'subscription_state_expired',
  [SubscriptionState.TRIAL_END]: 'subscription_state_trial_end',
};


/**
 * Icon for the subscription states.
 */
export const SUBSCRIPTION_STATE_ICON: {
  [key in SubscriptionState]: 'checkmark' | 'block' | 'error_outline';
} = {
  [SubscriptionState.PENDING]: 'error_outline',
  [SubscriptionState.TRIALING]: 'checkmark',
  [SubscriptionState.ACTIVE]: 'checkmark',
  [SubscriptionState.CANCELING]: 'error_outline',
  [SubscriptionState.PAST_DUE]: 'block',
  [SubscriptionState.EXPIRED]: 'block', // change if needed
  [SubscriptionState.TRIAL_END]: 'block', // change if needed
};

/**
 * Cadence period.
 */
export const CADENCE_PERIOD: { [key in SubscriptionCadence]: string } = {
  [SubscriptionCadence.DAILY]: 'day',
  [SubscriptionCadence.MONTHLY]: 'month',
  [SubscriptionCadence.ANNUAL]: 'year',
  [SubscriptionCadence.LIFETIME]: 'lifetime',
};

/**
 * Cadence display.
 */
export const CADENCE_DISPLAY: { [key in SubscriptionCadence]: string } = {
  [SubscriptionCadence.DAILY]: 'Daily',
  [SubscriptionCadence.MONTHLY]: 'Monthly',
  [SubscriptionCadence.ANNUAL]: 'Yearly',
  [SubscriptionCadence.LIFETIME]: 'Lifetime',
};

/**
 * Days in cadence period.
 */
export const CADENCE_DAYS: { [key in SubscriptionCadence]: number } = {
  [SubscriptionCadence.DAILY]: 1,
  [SubscriptionCadence.MONTHLY]: moment().daysInMonth(),
  [SubscriptionCadence.ANNUAL]: moment().isLeapYear() ? 366 : 365,
  [SubscriptionCadence.LIFETIME]: 0,
};

export type PostSubscriptionVehicleData = {
  vehicles: VehicleDTO[];
  connectionProvider: ProviderCredential | undefined;
  fleets?: number[];
};
