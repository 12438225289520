/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subscription, filter, firstValueFrom, map, switchMap, tap } from 'rxjs';
import { ProviderCredential } from 'src/app/admin/admin.models';
import { SSOAction, SSOProvider } from 'src/app/auth/pages/sso/sso.models';
import { getSSOProviderUri } from 'src/app/auth/pages/sso/sso.static';
import { selectSelectedCompany } from 'src/app/auth/store/auth.selectors';
import { Company, CompanyDataType } from 'src/app/auth/utility/auth.models';
import { HttpClientWrapper } from 'src/app/http-client-wrapper';
import {
  ActionPromptConfig,
  ActionPromptDialog,
} from 'src/app/shared/action-prompt/action-prompt.component';
import { Loadable } from 'src/app/shared/loading-state/loadable';
import { SubscriptionService } from 'src/app/shared/subscription/subscription.service';
import { Vehicle } from 'src/app/shared/vehicle/models/vehicle.class';
import { getProviderCredentials } from 'src/app/shared/vehicle/store/vehicle.actions';
import {
  selectProviderCredentials,
  selectVehicleList,
} from 'src/app/shared/vehicle/store/vehicle.selectors';
import { AppState } from 'src/app/store/app.reducers';
import { BillingCardDTO } from '../../admin/components/payment-form/payment-form.models';
import { getBillingCard } from '../../admin/store/general/admin.actions';
import { selectBillingCard } from '../../admin/store/general/admin.selectors';
import { SubscriptionState } from '../../shared/subscription/subscription.models';

@Injectable({ providedIn: 'root' })
export class DialogService {
  /**
   * Main subscription
   */
  private readonly subscription = new Subscription();

  /**
   * The selected company
   */
  private selectedCompany: Company | undefined;

  constructor(
    private dialog: MatDialog,
    private readonly subscriptionService: SubscriptionService,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly http: HttpClientWrapper,
    private translocoService: TranslocoService
  ) {}

  /**
   * Initializes the dialog service
   * @author Juan Corral
   */
  public async init(): Promise<void> {
    const preferredLanguage = localStorage.getItem('preferredLanguage') || 'en';

    await firstValueFrom(this.translocoService.load(preferredLanguage));

    const navigate$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),

      // Trigger persistent detection dialogs
      tap(() => void this._detectAdBlock()),

      // Trigger account-based detection dialogs
      switchMap(() => this.store.select(selectSelectedCompany)),
      filter((company?: Company) => this.selectedCompany !== company && company !== undefined),
      tap((company?: Company) => (this.selectedCompany = company)),

      // Trigger detection dialogs in all pages
      tap(() => void this._detectAdBlock()),
      tap(() => void this._detectTrialEnd()),
      tap(() => void this._detectExpiredSubscription()),

      // Trigger detection dialogs in non-auth pages
      filter(() => !this.router.url.includes('/auth')),
      tap(() => void this._detectPastDueSubscription()),
      tap(() => void this._detectExpiringPayment()),
      tap(() => void this._detectExpiredToken()),
      tap(() => void this._detectMissingTelemetryPrereqs())
    );

    this.subscription.add(navigate$.subscribe());
  }

  /**
   * Detects if the user has an ad-blocker enabled and prompts the user to disable it
   * @author Anna Chester
   */
  private async _detectAdBlock(): Promise<void> {
    const displayAds = await firstValueFrom(this.subscriptionService.displayAds$);
    if (!displayAds) return;

    const iframe = document.createElement('iframe');
    iframe.className = 'ad-zone ad-space ad-unit textads banner-ads banner_ads';
    iframe.height = '1px';
    iframe.src = 'https://ads.google.com/';
    iframe.id = 'some-ad';
    document.body.appendChild(iframe);

    setTimeout(() => {
      // Checks if the iframe got blocked due to an ad-blocker
      const someAd = document.getElementById('some-ad');
      if (someAd!.offsetHeight === 0) {
        const pastDueDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
          ActionPromptDialog,
          {
            width: '25rem',
            data: {
              icon: 'error_outline',
              heading: this.translocoService.translate('dialog.adblockDetected.heading'),
              message: this.translocoService.translate('dialog.adblockDetected.message'),
              removeCancelAction: true,
              confirmActionCaption: this.translocoService.translate(
                'dialog.adblockDetected.confirmActionCaption'
              ),
            },
          }
        );
        void firstValueFrom(pastDueDialog.afterClosed().pipe(tap(() => window.location.reload())));
      }
      iframe.remove();
    }, 1000);
  }

  /**
   * Detects if the subscription is past due and prompts the user to update their payment details
   * @author Samuel Bai
   */
  private async _detectPastDueSubscription(): Promise<void> {
    const subscription = await firstValueFrom(this.subscriptionService.subscription$);
    const state = subscription?.state;

    if (state !== SubscriptionState.PAST_DUE) return;

    const account = await firstValueFrom(this.store.select(selectSelectedCompany));
    const isCommercial =
      account?.data_type === CompanyDataType.FM || account?.data_type === CompanyDataType.SI;
    const date = moment(subscription!.next_billing_date)
      .add(subscription!.grace_period_days, 'days')
      .format('MMM DD, YYYY');
    const pastDueDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
      ActionPromptDialog,
      {
        width: '25rem',
        minHeight: '30.5rem',
        data: {
          icon: 'error_outline',
          heading: isCommercial ? 'Urgent: Payment Issue Detected' : 'Payment Issue Detected',
          messages: isCommercial
            ? [
                // eslint-disable-next-line max-len
                "We've encountered an issue with your account's payment method, leading to a potential " +
                  `expiration of your ZEVA for Fleets subscription on ${date}.  Without an update, your ` +
                  'access to ZEVA will be suspended.',
                'Please update your payment information to ensure uninterrupted service.',
              ]
            : [
                // eslint-disable-next-line max-len
                `We encountered a problem with your payment, and your subscription is set to expire on ${date}.`,
                'Please update your payment details to maintain access to ZEVA.',
              ],
          confirmActionCaption: isCommercial
            ? 'Update Payment Details'
            : 'Update My Payment Details',
          removeCancelAction: true,
        },
      }
    );
    void firstValueFrom(
      pastDueDialog.afterClosed().pipe(
        tap((result: boolean) => {
          if (result) void this.router.navigate(['admin', 'payment-details']);
        })
      )
    );
  }

  /**
   * Detects if the payment card will soon expire, and prompts user to update payment details
   * @author Anna Chester
   */
  private async _detectExpiringPayment() {
    const card$ = this.store.select(selectBillingCard).pipe(
      tap((card: Loadable<BillingCardDTO>) => {
        if (card.value === undefined && !card.isLoading && !card.error)
          this.store.dispatch(getBillingCard());
      }),
      filter(
        (card: Loadable<BillingCardDTO>) =>
          !card.isLoading && !card.error && card.value !== undefined
      ),
      map((card: Loadable<BillingCardDTO>) => {
        if (card.value && card.value.last_digits !== null) {
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth() + 1;
          return (
            card.value.expiration_year === currentYear &&
            card.value.expiration_month === currentMonth
          );
        }
        return false;
      })
    );

    const expiring = await firstValueFrom(card$);
    if (!expiring) return;

    const account = await firstValueFrom(this.store.select(selectSelectedCompany));
    const isCommercial =
      account?.data_type === CompanyDataType.FM || account?.data_type === CompanyDataType.SI;
    const expiryDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
      ActionPromptDialog,
      {
        width: '25rem',
        minHeight: '28.5rem',
        data: {
          icon: 'error_outline',
          heading: isCommercial
            ? this.translocoService.translate('dialog.expiringPayment.commercial.heading')
            : this.translocoService.translate('dialog.expiringPayment.personal.heading'),
          messages: isCommercial
            ? [
                this.translocoService.translate('dialog.expiringPayment.commercial.message1'),
                this.translocoService.translate('dialog.expiringPayment.commercial.message2'),
              ]
            : [
                this.translocoService.translate('dialog.expiringPayment.personal.message1'),
                this.translocoService.translate('dialog.expiringPayment.personal.message2'),
              ],
          removeCancelAction: true,
          confirmActionCaption: isCommercial
            ? this.translocoService.translate(
                'dialog.expiringPayment.commercial.confirmActionCaption'
              )
            : this.translocoService.translate(
                'dialog.expiringPayment.personal.confirmActionCaption'
              ),
        },
      }
    );

    void firstValueFrom(
      expiryDialog.afterClosed().pipe(
        tap((result: boolean) => {
          if (result) void this.router.navigate(['admin', 'payment-details']);
        })
      )
    );
  }

  /**
   * Opens the trial ended dialog
   * @author Juan Corral
   */
  private async _detectTrialEnd(): Promise<void> {
    const subscription = await firstValueFrom(this.subscriptionService.subscription$);
    const state = subscription?.state;

    if (state !== SubscriptionState.TRIAL_END) return;

    const account = await firstValueFrom(this.store.select(selectSelectedCompany));
    const isCommercial =
      account?.data_type === CompanyDataType.FM || account?.data_type === CompanyDataType.SI;

    const vehicles = await firstValueFrom(
      this.store.select(selectVehicleList).pipe(filter((v: Vehicle[] | null | undefined) => !!v))
    );

    const trialEndDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
      ActionPromptDialog,
      {
        width: '25rem',
        data: {
          icon: 'error_outline',
          heading: isCommercial
            ? this.translocoService.translate('dialog.trialEnd.commercial.heading')
            : this.translocoService.translate('dialog.trialEnd.personal.heading'),
          messages: isCommercial
            ? [
                this.translocoService.translate('dialog.trialEnd.commercial.message1'),
                this.translocoService.translate('dialog.trialEnd.commercial.message2'),
              ]
            : [
                this.translocoService.translate('dialog.trialEnd.personal.message1'),
                this.translocoService.translate('dialog.trialEnd.personal.message2'),
              ],
          removeCancelAction: true,
          // cancelActionCaption: isCommercial ? '' : 'Continue Free',
          confirmActionCaption: isCommercial
            ? this.translocoService.translate('dialog.trialEnd.commercial.confirmActionCaption')
            : this.translocoService.translate('dialog.trialEnd.personal.confirmActionCaption'),
        },
      }
    );

    void firstValueFrom(
      trialEndDialog.afterClosed().pipe(
        tap((result: boolean) => {
          if ((result || !isCommercial) && !subscription?.plan_instance.plan.mobile)
            void this.router.navigate(['auth', 'subscribe']);
          // } else if (!isCommercial) {
          //   void firstValueFrom(
          //     this.store.select(selectSubscriptionPlans).pipe(
          //       tap((plans: Loadable<ListDTO<SubscriptionPlanDTO>>) => {
          //         if (!plans.isLoading && plans.value === undefined)
          //           this.store.dispatch(getSubscriptionPlans());
          //       }),
          //       first((plans: Loadable<ListDTO<SubscriptionPlanDTO>>) => plans.value !== undefined),
          //       tap((plans: Loadable<ListDTO<SubscriptionPlanDTO>>) => {
          //         const freePlan = plans.value?.results.find(
          //           (plan: SubscriptionPlanDTO) => plan.free
          //         );
          //         const data = { plan: freePlan!.id, vehicle_spots: 5 };
          //         void firstValueFrom<boolean>(
          //           this.http.put<{ success: boolean }>(subscriptionsUrl + 'update/', data).pipe(
          //             catchError(() => of({ success: false })),
          //             map((response: { success: boolean }) => response.success)
          //           )
          //         ).then(async (success: boolean) => {
          //           if (success) {
          //             if (vehicles && vehicles?.length > 0) {
          //               await firstValueFrom(
          //                 this.http
          //                   .post<{ success: boolean }>(vehicleListUrl + 'set_premium/', {
          //                     premium: false,
          //                     vehicles: vehicles.map((v) => v.id),
          //                   })
          //                   .pipe(
          //                     catchError(() => of({ success: false })),
          //                     map((response: { success: boolean }) => response.success)
          //                   )
          //               ).then((vehicleSuccess: boolean) => {
          //                 if (vehicleSuccess) this.store.dispatch(getVehicleList());
          //                 if (success) this.store.dispatch(getSubscription());
          //               });
          //             }
          //           }
          //         });
          //       })
          //     )
          //   );
          // }
        })
      )
    );
  }

  /**
   * Opens the expired subscription dialog
   * @author Juan Corral
   */
  private async _detectExpiredSubscription(): Promise<void> {
    const subscription = await firstValueFrom(this.subscriptionService.subscription$);
    const state = subscription?.state;

    if (state !== SubscriptionState.EXPIRED) return;

    const account = await firstValueFrom(this.store.select(selectSelectedCompany));
    const isCommercial =
      account?.data_type === CompanyDataType.FM || account?.data_type === CompanyDataType.SI;

    let messages = [
      this.translocoService.translate('dialog.expiredSubscription.personal.message1'),
      this.translocoService.translate('dialog.expiredSubscription.personal.message2'),
    ];

    if (!isCommercial) {
      const vehicles = await firstValueFrom(
        this.store.select(selectVehicleList).pipe(filter((v: Vehicle[] | null | undefined) => !!v))
      );
      const archivedVehicles = vehicles!.filter((v: Vehicle) => v.archived);
      const numArchived = archivedVehicles.length;
      const nonArchivedVehicles = vehicles!.filter((v: Vehicle) => !v.archived);
      const numNonArchived = nonArchivedVehicles.length;

      messages = [
        this.translocoService.translate('dialog.expiredSubscription.commercial.message1'),
      ];
      if (numArchived === 0) {
        if (subscription?.plan_instance.plan.mobile) {
          messages.push(
            this.translocoService.translate('dialog.expiredSubscription.commercial.message2Mobile')
          );
        } else {
          messages.push(
            this.translocoService.translate('dialog.expiredSubscription.commercial.message2')
          );
        }
      } else {
        if (subscription?.plan_instance.plan.mobile) {
          messages.push(
            this.translocoService.translate('dialog.expiredSubscription.commercial.message3Mobile')
          );
        } else {
          messages.push(
            this.translocoService.translate('dialog.expiredSubscription.commercial.message3')
          );
        }
      }
    }

    const expiredDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
      ActionPromptDialog,
      {
        width: '25rem',
        data: {
          icon: 'error_outline',
          heading: isCommercial
            ? this.translocoService.translate('dialog.expiredSubscription.commercial.heading')
            : this.translocoService.translate('dialog.expiredSubscription.personal.heading'),
          messages: messages,
          removeCancelAction: isCommercial,
          cancelActionCaption: this.translocoService.translate(
            'dialog.expiredSubscription.cancelActionCaption'
          ),
          confirmActionCaption: this.translocoService.translate(
            'dialog.expiredSubscription.confirmActionCaption'
          ),
          disableClose: true,
        },
      }
    );

    void firstValueFrom(
      expiredDialog.afterClosed().pipe(
        tap((result: boolean) => {
          if (result && !subscription?.plan_instance.plan.mobile)
            void this.router.navigate(['auth', 'subscribe']);
          else void this.router.navigate(['admin', 'subscription']);
          // } else if (!isCommercial) {
          //   void firstValueFrom(
          //     this.store.select(selectSubscriptionPlans).pipe(
          //       tap((plans: Loadable<ListDTO<SubscriptionPlanDTO>>) => {
          //         if (!plans.isLoading && plans.value === undefined)
          //           this.store.dispatch(getSubscriptionPlans());
          //       }),
          //       first((plans: Loadable<ListDTO<SubscriptionPlanDTO>>) => plans.value !== undefined),
          //       tap((plans: Loadable<ListDTO<SubscriptionPlanDTO>>) => {
          //         const freePlan = plans.value?.results.find(
          //           (plan: SubscriptionPlanDTO) => plan.free
          //         );
          //         const data = { plan: freePlan!.id, vehicle_spots: 5 };
          //         void firstValueFrom<boolean>(
          //           this.http.put<{ success: boolean }>(subscriptionsUrl + 'update/', data).pipe(
          //             catchError(() => of({ success: false })),
          //             map((response: { success: boolean }) => response.success)
          //           )
          //         ).then((success: boolean) => {
          //           if (success) this.store.dispatch(getSubscription());
          //         });
          //       })
          //     )
          //   );
          // }
        })
      )
    );
  }

  /**
   * Detects if the provider token has expired and prompts the user to sign in again
   * @author Juan Corral
   */
  private async _detectExpiredToken(): Promise<void> {
    // Retrieve the provider credentials
    const credentials$ = this.store.select(selectProviderCredentials).pipe(
      tap((credentials: Loadable<ProviderCredential[]>) => {
        if (!credentials.value && !credentials.isLoading)
          this.store.dispatch(getProviderCredentials());
      }),
      filter(
        (credentials: Loadable<ProviderCredential[]>) =>
          credentials.value !== null && credentials.value !== undefined && !credentials.isLoading
      ),
      map((credentials: Loadable<ProviderCredential[]>) => credentials.value!)
    );
    const credentials = await firstValueFrom(credentials$);
    if (!credentials) return;

    // Retrieve the tokens that need to be refreshed
    const toRefresh = credentials.filter(
      (token: ProviderCredential) => !token.is_active && !token.revoked
    );
    if (toRefresh.length === 0) return;

    // Open the dialog to refresh the tokens
    const refreshDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
      ActionPromptDialog,
      {
        width: '25rem',
        data: {
          icon: 'error_outline',
          heading: this.translocoService.translate('dialog.expiredToken.heading'),
          message: this.translocoService.translate('dialog.expiredToken.message', {
            provider: toRefresh[0].provider_name,
            email: toRefresh[0].provider_user_email,
          }),
          confirmActionCaption: this.translocoService.translate(
            'dialog.expiredToken.confirmActionCaption'
          ),
          removeCancelAction: true,
          disableClose: true,
        },
      }
    );
    void firstValueFrom(
      refreshDialog.afterClosed().pipe(
        tap((result: boolean) => {
          if (result) {
            const provider = toRefresh[0].provider_name.toLowerCase() as SSOProvider;
            const url = getSSOProviderUri(
              provider,
              SSOAction.RECONNECT,
              window.location.href,
              this.selectedCompany,
              toRefresh[0].provider_user_email
            );
            if (url !== undefined) window.location.href = url;
          }
        })
      )
    );
  }

  /**
   * Detects if any vehicles don't have the necessary prereqs for Telemetry
   * @author Anna Chester
   */
  private async _detectMissingTelemetryPrereqs(): Promise<void> {
    const account = await firstValueFrom(this.store.select(selectSelectedCompany));
    const isCommercial =
      account?.data_type === CompanyDataType.FM || account?.data_type === CompanyDataType.SI;

    const vehicles = await firstValueFrom(
      this.store.select(selectVehicleList).pipe(filter((v: Vehicle[] | null | undefined) => !!v))
    );

    if (
      vehicles?.find((v: Vehicle) => {
        return (
          v.configured_virtual_key === false || v.unsupported_firmware || v.unsupported_hardware
        );
      }) !== undefined
    ) {
      // Open the dialog to manage vehicles
      const refreshDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
        ActionPromptDialog,
        {
          width: '35rem',
          data: {
            title: this.translocoService.translate('dialog.missingTelemetry.title'),
            messages: [
              this.translocoService.translate('dialog.missingTelemetry.message'),
              this.translocoService.translate('dialog.missingTelemetry.newRequirements'),
              this.translocoService.translate('dialog.missingTelemetry.vehicleRequirement'),
              this.translocoService.translate('dialog.missingTelemetry.firmwareRequirement'),
              this.translocoService.translate('dialog.missingTelemetry.virtualKeyRequirement'),
            ],
            confirmActionCaption: this.translocoService.translate(
              'dialog.missingTelemetry.confirmActionCaption'
            ),
          },
        }
      );
      void firstValueFrom(
        refreshDialog.afterClosed().pipe(
          tap((result: boolean) => {
            if (result) {
              isCommercial
                ? void this.router.navigate(['admin', 'fleet'])
                : void this.router.navigate(['admin', 'manage-vehicles']);
            }
          })
        )
      );
    }
  }
}
