import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, filter, map, tap } from 'rxjs';
import { Loadable } from 'src/app/shared/loading-state/loadable';
import { selectSelectedCompany, selectSession } from '../../auth/store/auth.selectors';
import { Company, CompanyDataType } from '../../auth/utility/auth.models';
import { getSubscription } from './store/subscription.actions';
import { selectSubscription } from './store/subscription.selectors';
import { PlanType, PostSubscriptionVehicleData, SubscriptionDTO } from './subscription.models';

@Injectable()
export class SubscriptionService {
  private data: PostSubscriptionVehicleData | null = null;

  /**
   * Subscription error.
   */
  public subscriptionError: HttpErrorResponse | string | null = null;

  /**
   * Selected company observable.
   */
  public company$: Observable<Company | undefined> = combineLatest([
    this.store.select(selectSession),
    this.store.select(selectSelectedCompany),
  ]).pipe(
    map(([isAuthenticated, company]: [boolean | undefined, Company | undefined]) =>
      isAuthenticated ? company : undefined
    )
  );

  /**
   * Subscription observable.
   */
  public subscription$: Observable<SubscriptionDTO | null> = combineLatest([
    this.company$,
    this.store.select(selectSubscription),
  ]).pipe(
    filter(([company]: [Company | undefined, Loadable<SubscriptionDTO>]) => company !== undefined),
    map(([, subscription]: [Company | undefined, Loadable<SubscriptionDTO>]) => subscription),
    tap((subscription: Loadable<SubscriptionDTO>) => {
      if (subscription.value === undefined && !subscription.isLoading && !subscription.error)
        this.store.dispatch(getSubscription());
    }),
    filter((subscription: Loadable<SubscriptionDTO>) => !subscription.isLoading),
    map((subscription: Loadable<SubscriptionDTO>) => {
      this.subscriptionError = subscription.error ?? null;
      if (subscription.error) return null;
      return subscription.value!;
    }),
    filter((subscription: SubscriptionDTO | null) => subscription !== undefined)
  );

  /**
   * True if the selected company displays ads.
   */
  public displayAds$: Observable<boolean> = this.subscription$.pipe(
    map((subscription: SubscriptionDTO | null) => !!subscription?.display_ads)
  );

  /**
   * True if the selected company is on a free plan.
   */
  public isFreePlan$: Observable<boolean> = this.subscription$.pipe(
    map((subscription: SubscriptionDTO | null) => !!subscription?.plan_instance.plan.free)
  );

  /**
   * True if the selected company is on a free plan.
   */
  public isCommercialPlan$: Observable<boolean> = this.subscription$.pipe(
    map(
      (subscription: SubscriptionDTO | null) =>
        subscription!.plan_instance.plan.type === PlanType.COMMERCIAL
    )
  );

  /**
   * True if the selected company is on a mobile plan.
   */
  public isMobilePlan$: Observable<boolean> = this.subscription$.pipe(
    map((subscription: SubscriptionDTO | null) => !!subscription?.plan_instance.plan.mobile)
  );

  setVehicleData(data: PostSubscriptionVehicleData | null) {
    this.data = data;
  }

  getVehicleData() {
    return this.data;
  }

  constructor(private readonly store: Store) {}

  /**
   * Returns true if the selected company has the given data type.
   * @param {CompanyDataType | CompanyDataType[]} dataType - The data type(s) to check.
   * @returns {Observable<boolean>} - True if the selected company has the given data type.
   * @author Juan Corral
   */
  public hasDataType(dataType: CompanyDataType | CompanyDataType[]): Observable<boolean> {
    if (Array.isArray(dataType)) {
      return this.company$.pipe(
        map((company?: Company) =>
          dataType.some((type: CompanyDataType) => company?.data_type === type)
        )
      );
    }
    return this.company$.pipe(map((company?: Company) => company?.data_type === dataType));
  }
}
