import { createSelector } from '@ngrx/store';
import { selectFeatureVehicle } from 'src/app/store/app.selectors';
import { VehicleState } from './vehicle.reducer';

export const selectLoadingStatus = createSelector(
  selectFeatureVehicle,
  (state: VehicleState) => state.loadingStatus
);

export const selectVehicleList = createSelector(
  selectFeatureVehicle,
  (state: VehicleState) => state.vehicleList
);

export const selectFleetList = createSelector(
  selectFeatureVehicle,
  (state: VehicleState) => state.fleetList
);

export const selectFleetMap = createSelector(
  selectFeatureVehicle,
  (state: VehicleState) => state.fleetMap
);

export const selectProviderCredentials = createSelector(
  selectFeatureVehicle,
  (state: VehicleState) => state.providerCredentials
);
