import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';
import { selectUserProfile } from 'src/app/auth/store/auth.selectors';
import { CardModule } from 'src/app/core/card-grid/components/card/card.module';
import { Theme, ThemeService } from 'src/app/core/services/theme.service';
import { ZebotThread } from '../../insights.models';
import { InsightsDataService } from '../../store/general/insights-data.service';

@Component({
  standalone: true,
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    CommonModule,
    CardModule,
  ],
  selector: 'insights-zebot',
  templateUrl: './zebot.component.html',
  styleUrls: ['./zebot.component.scss'],
})
export class ZebotComponent {
  /* Chat container reference */
  @ViewChild('chatContainer') chatContainer!: ElementRef;

  /* Event emitters */
  @Output() public readonly close = new EventEmitter<void>();
  @Output() public readonly minimize = new EventEmitter<void>();

  /* Chat messages */
  public messages: {
    sender: 'bot' | 'user';
    text: string;
    thinking?: boolean;
    feedback?: boolean;
  }[] = [];

  /* Loading states */
  public awaitingFeedback = signal(false);

  /* Last conversation thread */
  private _lastThread: ZebotThread | undefined;

  /* End Chat flag */
  public endChatFlag = signal(false);

  /* Constants */
  public readonly Theme = Theme;

  constructor(
    public readonly themeService: ThemeService,
    private readonly store: Store,
    private readonly _dataService: InsightsDataService,
    private readonly _cdr: ChangeDetectorRef
  ) {}

  /**
   * Starts the chat with Zebot.
   * @author Juan Corral
   */
  public async startChat(): Promise<void> {
    if (this.messages.length > 0) return;

    const user = await firstValueFrom(
      this.store.select(selectUserProfile).pipe(filter((u) => u !== undefined && u !== null))
    );

    const messages = [
      '...',
      `Hello ${user!.first_name}! 👋`,
      '...',
      "I'm Alan, your AI vehicle analytics assistant, and I can help with finding information quickly in ZEVA so you can get back to work.",
      '...',
      'Ask me anything, like "What was the total mileage of my vehicle for the month of June?"',
      '...',
      'By default, I will limit my responses to the last 30 days. If you need more data, specify the time range in your question.',
      '...',
      "I'm still in beta, but I'll do my best to help!",
    ];
    let index = 0;
    const displayMessage = () => {
      if (index < messages.length) {
        if (messages[index] !== '...') this.messages.pop();
        this.showBotMessage(messages[index]);
        index++;
        setTimeout(displayMessage, 400);
      }
    };
    displayMessage();
  }

  /**
   * Sends a message to Zebot.
   * @param {string} userInput - The user's message.
   * @author Juan Corral
   */
  public sendMessage(userInput: string): void {
    if (this.awaitingFeedback()) return;
    this.messages.push({ sender: 'user', text: userInput });
    this.showBotMessage('...');
    setTimeout(() => {
      this._dataService.askZebot(userInput, this._lastThread).subscribe((response: ZebotThread) => {
        this._lastThread = response;
        this.messages.pop();
        this.showBotMessage(response.last_answer);
        if (response.completed) this.askForFeedback();
      });
    }, 300);
  }

  /**
   * Asks the user for feedback on the last answer provided by Zebot.
   * @author Juan Corral
   */
  private askForFeedback(): void {
    this.awaitingFeedback.set(true);
    this.showBotMessage('...');
    setTimeout(() => {
      this.messages.pop();
      this.showBotMessage('Please help me improve... Does this answer make sense?');
    }, 300);
  }

  /**
   * Provides feedback to the last answer provided by Zebot.
   * @param {boolean} feedback - Whether the answer was perceived as correct or not.
   * @author Juan Corral
   */
  public provideFeedback(feedback: boolean): void {
    this.messages[this.messages.length - 1].feedback = false;
    this.messages.push({ sender: 'user', text: feedback ? 'Yes' : 'No' });
    if (this._lastThread === undefined) return;
    this.awaitingFeedback.set(false);
    this.showBotMessage('...');
    this._dataService.sendZebotFeedback(this._lastThread, feedback).subscribe(() => {
      this._lastThread = undefined;
      setTimeout(() => {
        this.messages.pop();
        this.showBotMessage(
          feedback
            ? 'Thanks for your feedback! What else can I help with?'
            : 'Sorry about that. Please try rephrasing your question or ask something else.'
        );
      }, 300);
    });
  }

  /**
   * Writes a message from Zebot to the user.
   * @param {string} text - The message to show.
   * @author Juan Corral
   */
  private showBotMessage(text: string): void {
    this.messages.push({
      sender: 'bot',
      text,
      thinking: text === '...',
      feedback: text === 'Please help me improve... Does this answer make sense?',
    });
    this._cdr.markForCheck();
    setTimeout(() => this.scrollToBottom(), 100);
  }

  /**
   * Scrolls to the bottom message.
   * @author Juan Corral
   */
  private scrollToBottom(): void {
    this.chatContainer?.nativeElement?.scrollTo({
      top: this.chatContainer.nativeElement.scrollHeight,
      behavior: 'smooth',
    });
  }

  /**
   * Ends the chat with Zebot.
   * @author Juan Corral
   */
  public endChat(): void {
    this.close.emit();
    this.messages = [];
    this.awaitingFeedback.set(false);
    this._lastThread = undefined;
    this.endChatFlag.set(false);
  }
}
