export const CUSTOM_ICON_REGISTRY = [
  { icon: 'parking', url: '/assets/icons/svg/parking.svg' },
  { icon: 'pending', url: '/assets/icons/svg/pending.svg' },
  { icon: 'temperature', url: '/assets/icons/svg/temperature.svg' },
  { icon: 'moving', url: '/assets/icons/svg/moving.svg' },
  { icon: 'moving_2', url: '/assets/icons/svg/moving_2.svg' },
  { icon: '403', url: '/assets/icons/svg/403.svg' },
  { icon: '404', url: '/assets/icons/svg/404.svg' },
  { icon: '500', url: '/assets/icons/svg/500.svg' },
  { icon: 'ac', url: '/assets/icons/svg/ac.svg' },
  { icon: 'trash_can', url: '/assets/icons/svg/trash-can.svg' },
  { icon: 'add', url: '/assets/icons/svg/add.svg' },
  { icon: 'wipers', url: '/assets/icons/svg/wipers.svg' },
  { icon: 'alerts', url: '/assets/icons/svg/alerts.svg' },
  { icon: 'battery_level', url: '/assets/icons/svg/battery-level.svg' },
  { icon: 'charging', url: '/assets/icons/svg/charging.svg' },
  { icon: 'charging_2', url: '/assets/icons/svg/charging_2.svg' },
  { icon: 'trunk', url: '/assets/icons/svg/trunk.svg' },
  { icon: 'defrost', url: '/assets/icons/svg/defrost.svg' },
  { icon: 'flash', url: '/assets/icons/svg/flash.svg' },
  { icon: 'horn', url: '/assets/icons/svg/horn.svg' },
  { icon: 'check', url: '/assets/icons/svg/import_check.svg' },
  { icon: 'wifi', url: '/assets/icons/svg/wifi.svg' },
  { icon: 'lock', url: '/assets/icons/svg/lock.svg' },
  { icon: 'unlock', url: '/assets/icons/svg/unlock.svg' },
  { icon: 'map_pointer', url: '/assets/icons/svg/map_pointer.svg' },
  { icon: 'upload', url: '/assets/icons/svg/upload.svg' },
  { icon: 'moon', url: '/assets/icons/svg/moon.svg' },
  { icon: 'online', url: '/assets/icons/svg/online.svg' },
  { icon: 'zeva_navigation', url: '/assets/icons/svg/navigation.svg' },
  { icon: 'search', url: '/assets/icons/svg/search.svg' },
  { icon: 'calendar', url: '/assets/icons/svg/calendar.svg' },
  { icon: 'notification', url: '/assets/icons/svg/notification.svg' },
  { icon: 'pause', url: '/assets/icons/svg/paused.svg' },
  { icon: 'check_circle', url: '/assets/icons/svg/check.svg' },
  { icon: 'flag', url: '/assets/icons/svg/flag.svg' },
  { icon: 'no_data', url: '/assets/icons/svg/no-data.svg' },
  { icon: 'odometer', url: '/assets/icons/svg/odometer.svg' },
  { icon: 'caution', url: '/assets/icons/svg/caution.svg' },
  { icon: 'checkmark', url: '/assets/icons/svg/check-mark-circle.svg' },
  { icon: 'error_outline', url: '/assets/icons/svg/error.svg' },
  { icon: 'diamond', url: '/assets/icons/svg/diamond-stone.svg' },
  { icon: 'arrow_up_outline', url: '/assets/icons/svg/arrow-up-outline.svg' },
  { icon: 'arrow_down_outline', url: '/assets/icons/svg/arrow-down-outline.svg' },
  { icon: 'world', url: '/assets/icons/svg/world.svg' },
  { icon: 'charge-limit', url: '/assets/icons/svg/charge-limit.svg' },
  { icon: 'charge-limit-white', url: '/assets/icons/svg/charge-limit-white.svg' },
  // { icon: 'climate-keeper-off', url: '/assets/icons/svg/' },
  { icon: 'climate-keeper', url: '/assets/icons/svg/climate-keeper.svg' },
  { icon: 'climate-keeper-dog', url: '/assets/icons/svg/climate-keeper-dog.svg' },
  { icon: 'climate-keeper-camp', url: '/assets/icons/svg/climate-keeper-camp.svg' },
  { icon: 'climate-keeper-keep', url: '/assets/icons/svg/climate-keeper-keep.svg' },
  { icon: 'preconditioning', url: '/assets/icons/svg/preconditioning.svg' },
  { icon: 'overheat-protection', url: '/assets/icons/svg/overheat-protection.svg' },
  { icon: 'recenter', url: '/assets/icons/svg/recenter.svg' },
  { icon: 'expand', url: '/assets/icons/svg/expand.svg' },
  { icon: 'expand-dark', url: '/assets/icons/svg/expand-dark.svg' },
  { icon: 'collapse', url: '/assets/icons/svg/collapse.svg' },
  { icon: 'wind-normal', url: '/assets/icons/svg/wind-normal.svg' },
  { icon: 'wind-warning', url: '/assets/icons/svg/wind-warning.svg' },
  { icon: 'wind-danger', url: '/assets/icons/svg/wind-danger.svg' },
  { icon: 'wind-direction', url: '/assets/icons/svg/wind-direction.svg' },
  { icon: 'add-photo', url: '/assets/icons/svg/add-photo.svg' },
  { icon: 'person-add', url: '/assets/icons/svg/person-add.svg' },
  { icon: 'cancel', url: '/assets/icons/svg/cancel.svg' },
  { icon: 'push-nav', url: '/assets/icons/svg/push-nav.svg' },
  { icon: 'see-more', url: '/assets/icons/svg/see-more.svg' },
  { icon: 'zebot', url: '/assets/icons/svg/zebot.svg' },
  { icon: 'send', url: '/assets/icons/svg/send.svg' },
  // Provider icons
  { icon: 'tesla', url: '/assets/providers/Tesla-icon.svg' },
];
